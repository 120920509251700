import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import SEO from 'src/components/SEO';
import Heading, { HeadingVariant } from 'src/components/Heading';
import Layout from 'src/components/Layout';
import Content from 'src/components/Content';
import Grid from 'src/components/Grid';

const CenteredGrid = styled(Grid)`
  align-items: center;
`;

const ClientsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Content>
        <CenteredGrid columns={2}>
          <div>
            <Heading variant={HeadingVariant.BRAVO}>
              <Trans i18nKey="projects_heading" />
            </Heading>
            <p>
              <Trans i18nKey="projects_paragraph_1" />
            </p>
            <p>
              <Trans i18nKey="projects_paragraph_2" />
            </p>
          </div>

          <StaticImage
            layout="fullWidth"
            alt={t('projects_banner_alt')}
            src="../assets/images/clients.png"
            formats={['auto', 'webp', 'avif']}
            placeholder="blurred"
          />
        </CenteredGrid>
      </Content>
    </Layout>
  );
};

export default ClientsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO title="Clients | Instegral" />;
